













































































































































import {
    defineComponent,
    onBeforeUnmount,
    onUnmounted,
    reactive,
    toRefs,
} from "@vue/composition-api"
import {
    getMissions,
    MissionState,
    missionAddComputedInfo,
    missionTypeFormatter,
    missionTimeInfoFormatter,
    MissionViewModelWithComputedInfo,
} from "@/services/MissionsService"
import { timeFormatter } from "@/utilities/Formatter"
import dayjs from "dayjs"
import { now } from "@/services/TimeService"
import { onMessage, offMessage, MQTTMessage } from "@/services/MqttClient"

export default defineComponent({
    setup() {
        const state = reactive({
            overdueMissions: [] as MissionViewModelWithComputedInfo[],
            overdueMissionCount: 0,
            overdueMissionLink: "",
            todayMissions: [] as MissionViewModelWithComputedInfo[],
            todayMissionCount: 0,
            todayMissionLink: "",
        })
        async function loadData() {
            const overdueResult = await getMissions({
                take: 10,
                hasRoleOnly: true,
                states: [MissionState.Opened],
                overdueTimeEnd: dayjs().toISOString(),
            })
            state.overdueMissions = overdueResult.items.map((m) =>
                missionAddComputedInfo(m)
            )
            state.overdueMissionCount = overdueResult.total
            state.overdueMissionLink = `/mission?overdueTime_e=${dayjs(
                now.value
            ).toISOString()}`

            const todayResult = await getMissions({
                take: 10,
                hasRoleOnly: true,
                states: [MissionState.Opened],
                workTimeStart: dayjs().startOf("d").toISOString(),
                workTimeEnd: dayjs().startOf("d").add(1, "d").toISOString(),
            })
            state.todayMissions = todayResult.items.map((m) =>
                missionAddComputedInfo(m)
            )
            state.todayMissionCount = todayResult.total
            state.todayMissionLink = `/mission?workTime_s=${dayjs()
                .startOf("d")
                .toISOString()}&workTime_e=${dayjs()
                .startOf("d")
                .add(1, "d")
                .toISOString()}`
        }

        loadData()

        const updateInterval = setInterval(() => loadData(), 1000 * 60)

        function mqttMessageHandler(model: MQTTMessage) {
            console.log("mqtt message recive", model.payload.toString())
        }

        const MQTT_TOPIC = "public/test"
        onMessage(MQTT_TOPIC, mqttMessageHandler)
        onBeforeUnmount(() => {
            clearInterval(updateInterval)
            offMessage(MQTT_TOPIC, mqttMessageHandler)
        })

        return {
            ...toRefs(state),
            missionTypeFormatter,
            timeFormatter,
            missionTimeInfoFormatter,
        }
    },
})
